import { getLocalizedNumberOfPayingTeams } from "constants/localized-number-of-customers";
import BasePatternDataService from "./base-pattern-data-service";
// "This is an <monday-paying-companies /> example"
const REGEX_EXPRESSION = "(<monday-paying-companies />)";
class PayingCompaniesPatternDataService extends BasePatternDataService {
    regex() {
        return REGEX_EXPRESSION;
    }
    isRecursive() {
        return false;
    }
    replacer(value) {
        return `${getLocalizedNumberOfPayingTeams(this.localeId)}`;
    }
}
export { PayingCompaniesPatternDataService as default };
