import values from "lodash/values";
import BasePatternDataService from "./base-pattern-data-service";
const FONT_SIZES = {
    SM: "sm",
    MD: "md",
    LG: "lg"
};
const FONT_SIZE_MAPPING = {
    [FONT_SIZES.SM]: 1.8,
    [FONT_SIZES.MD]: 2.7,
    [FONT_SIZES.LG]: 3.6
};
const FONT_SIZE_REGEX = values(FONT_SIZES).join("|");
//example: <monday-font-size lg>large text</monday-font-size>
const REGEX_EXPRESSION = `(<monday-font-size .+?>.+</monday-font-size>)`;
const INNER_REGEX_EXPRESSION = `(<monday-font-size (${FONT_SIZE_REGEX})>(.+)</monday-font-size>)`;
class FontSizePatternDataService extends BasePatternDataService {
    regex() {
        return REGEX_EXPRESSION;
    }
    isRecursive() {
        return false;
    }
    patternCharsCount() {
        return 0;
    }
    replacer(value) {
        const result = value.match(INNER_REGEX_EXPRESSION);
        const fontSizeStr = result[2];
        const fontSize = FONT_SIZE_MAPPING[fontSizeStr];
        const text = result[3];
        return `<span style="font-size: ${fontSize}rem;" >${text}</span>`;
    }
}
export { FontSizePatternDataService as default };
