import PatternDataServices from "./pattern-data-services";
const buildRegexPattern = ()=>{
    return PatternDataServices.map((patternDataService)=>patternDataService.regex()).join("|");
};
export const getOnMountHandlers = ()=>PatternDataServices.map((patternDataService)=>patternDataService.onMountHandler);
export const getOnUnmountHandlers = ()=>PatternDataServices.map((patternDataService)=>patternDataService.onUmountHandler);
export const parseText = (text, localeId)=>{
    try {
        const regexPatterns = buildRegexPattern();
        const regex = new RegExp(regexPatterns);
        let finalText = "";
        let result = "";
        while(result = text.match(regex)){
            PatternDataServices.forEach((patternDataService, index)=>{
                patternDataService.locale = localeId;
                const group = index + 1;
                const value = result[group];
                if (value) {
                    const markupTagPrefix = text.substring(0, result.index);
                    const currentValue = `${finalText}${markupTagPrefix}${patternDataService.replacer(value)}`;
                    finalText = patternDataService.isRecursive() ? parseText(currentValue, localeId) : currentValue;
                    const offset = Math.min(result.index + value.length + patternDataService.patternCharsCount(), text.length);
                    text = text.substring(offset, text.length);
                }
            });
        }
        return finalText + text;
    } catch (error) {
        console.error("Error in parseText:", error);
        return text;
    }
};
