import BasePatternDataService from "./base-pattern-data-service";
//example: <monday-bold>this is bold text</monday-bold>
const REGEX_EXPRESSION = "(<monday-bold>.+</monday-bold>)";
const INNER_REGEX_EXPRESSION = "(<monday-bold>(.+)</monday-bold>)";
class BoldPatternDataService extends BasePatternDataService {
    regex() {
        return REGEX_EXPRESSION;
    }
    isRecursive() {
        return true;
    }
    patternCharsCount() {
        return 0;
    }
    replacer(value) {
        const result = value.match(INNER_REGEX_EXPRESSION);
        const text = result[2];
        return `<b>${text}</b>`;
    }
}
export { BoldPatternDataService as default };
