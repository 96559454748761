import BasePatternDataService from "./base-pattern-data-service";
import { trackEvent } from "/services/bigbrain-service";
import { MONDAY_HYPERLINK_CLICKED } from "/constants/bigbrain-event-types";
const HYPER_LINK_REGEX = ".+?";
//example: <monday-hyperlink href="http://somewebsite.com">Click Here</monday-hyperlink>
const REGEX_EXPRESSION = `(<monday-hyperlink ${HYPER_LINK_REGEX}>.+</monday-hyperlink>)`;
const INNER_REGEX_EXPRESSION = `(<monday-hyperlink href="(${HYPER_LINK_REGEX})">(.+)</monday-hyperlink>)`;
class HyperlinkPatternDataService extends BasePatternDataService {
    regex() {
        return REGEX_EXPRESSION;
    }
    static onClick(e) {
        if (e.target.hasAttribute('data-monday-hyperlink')) {
            trackEvent(MONDAY_HYPERLINK_CLICKED, {
                info1: e.target.innerText,
                info2: e.target.href
            });
        }
    }
    onMountHandler(containerElement) {
        containerElement === null || containerElement === void 0 ? void 0 : containerElement.addEventListener('click', HyperlinkPatternDataService.onClick);
    }
    onUmountHandler(containerElement) {
        containerElement === null || containerElement === void 0 ? void 0 : containerElement.removeEventListener('click', HyperlinkPatternDataService.onClick);
    }
    replacer(value) {
        const result = value.match(INNER_REGEX_EXPRESSION);
        const href = result[2];
        const text = result[3];
        return `<a href="${href}" style="font: inherit;" data-monday-hyperlink>${text}</a>`;
    }
}
export { HyperlinkPatternDataService as default };
