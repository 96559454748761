import ColorPatternDataService from "./pattern-data-service/color-pattern-data-service";
import DotPatternDataService from "./pattern-data-service/dot-pattern-data-service";
import PayingCompaniesPatternDataService from "./pattern-data-service/paying-companies-pattern-data-service";
import NewLinePatternDataService from "./pattern-data-service/new-line-pattern-data-service";
import BoldPatternDataService from "./pattern-data-service/bold-pattern-data-service";
import UrlParamDataService from "./pattern-data-service/url-param-data-service";
import FontSizePatternDataService from "./pattern-data-service/font-size-pattern-data-service";
import HyperlinkPatternDataService from "./pattern-data-service/hyperlink-pattern-data-service";
export default [
    ColorPatternDataService,
    DotPatternDataService,
    PayingCompaniesPatternDataService,
    NewLinePatternDataService,
    UrlParamDataService,
    BoldPatternDataService,
    FontSizePatternDataService,
    HyperlinkPatternDataService
].map((patternDataService)=>new patternDataService());
