function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { getUrlParamValueFromCurrentLocation } from "utils/url";
import BasePatternDataService from "./base-pattern-data-service";
const url_param_regex = ".+?";
const fallback_param_regex = ".+?";
// "Hi, <monday-url-param name friend>! This is an example of usage for the link /page?name=barak or /page."
const INNER_REGEX_EXPRESSION = `(<monday-url-param (${url_param_regex}) (${fallback_param_regex}) />)`;
const REGEX_EXPRESSION = `(<monday-url-param .+? />)`;
class UrlParamDataService extends BasePatternDataService {
    regex() {
        return REGEX_EXPRESSION;
    }
    isRecursive() {
        return false;
    }
    patternCharsCount() {
        return 0;
    }
    replacer(value) {
        const result = value.match(INNER_REGEX_EXPRESSION);
        const paramName = result[2];
        const fallback = result[3];
        return `${this.getParamValueFromUrl(paramName) || fallback}`;
    }
    constructor(...args){
        super(...args);
        _define_property(this, "getParamValueFromUrl", (paramName)=>getUrlParamValueFromCurrentLocation(paramName) || "");
    }
}
export { UrlParamDataService as default };
