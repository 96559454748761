import styled from "@emotion/styled";
export const StyledPictureComponent = styled.picture`
  &.blur-image img {
    filter: blur(10px);
  }

  .picture-lazy {
    opacity: 0;
  }
`;
