import { PICTURE_LAZY_CLASS, PICTURE_LAZY_ATTRIBUTE } from "constants/lazy-load-images";
export const lazyLoad = (targetImage)=>{
    const io = new IntersectionObserver((imageEntries, observer)=>{
        imageEntries.forEach((entry)=>{
            if (entry.isIntersecting) {
                const img = entry.target;
                removeLazyLoadProperty(img);
                observer.disconnect();
            }
        });
    });
    io.observe(targetImage);
};
export const removeLazyLoadProperty = (img)=>{
    const src = img.getAttribute(PICTURE_LAZY_ATTRIBUTE);
    if (src) {
        img.setAttribute("src", src);
        img.classList.remove(PICTURE_LAZY_CLASS);
        img.removeAttribute(PICTURE_LAZY_ATTRIBUTE);
    }
};
