class BasePatternDataService {
    set locale(val) {
        this.localeId = val;
    }
    regex() {
        return "";
    }
    isRecursive() {
        return false;
    }
    patternCharsCount() {
        return 0;
    }
    onMountHandler(containerElement) {}
    onUmountHandler(containerElement) {}
    replacer() {
        return null;
    }
    constructor(){}
}
export { BasePatternDataService as default };
