import BasePatternDataService from "./base-pattern-data-service";
import colors from "styles/colors";
// "This is an <monday-dot blue /> Example"
const INNER_REGEX_EXPRESSION = "(<monday-dot (.+?) />)";
const REGEX_EXPRESSION = "(<monday-dot .+? />)";
class DotPatternDataService extends BasePatternDataService {
    regex() {
        return REGEX_EXPRESSION;
    }
    isRecursive() {
        return false;
    }
    replacer(value) {
        const result = value.match(INNER_REGEX_EXPRESSION);
        const color = result[2];
        return `<span style="color: ${colors[color]}; font: inherit; font-size: 3.263rem;padding-left: 2px;">.</span>`;
    }
}
export { DotPatternDataService as default };
